import {MagazineState} from "@/utils/interfaces/magazine.interface";
import {Promise} from "cypress/types/cy-bluebird";

export default {
  getAllMags: (state: MagazineState) => state.allMags,
  getUserSubscribedMags: (state: MagazineState) => state.userSubscribedMags,
  getMagToDisplayInSummary: (state: MagazineState) => state.clickedMag,
  getMagToReadOrBuy: (state: MagazineState) => state.clickedMag,
  getSingleMag: (state: MagazineState) => state.singleMag
}
