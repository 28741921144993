import {Magazine, MagazineState, SubscribedMag} from "@/utils/interfaces/magazine.interface";

export default {
  SET_ALL_MAGS(state: MagazineState, value: Magazine[]) {
    state.allMags = value
  },

  SET_USER_SUBSCRIBED_MAGS(state: MagazineState, value: SubscribedMag[]) {
    state.userSubscribedMags = value
  },

  DISPLAY_SUMMARY(state: MagazineState, value: Magazine) {
    state.clickedMag = value
  },

  BUY_OR_READ(state: MagazineState, value: Magazine) {
    state.clickedMag = value
  },

  SET_SINGLE_MAG(state: MagazineState, value: Magazine) {
    state.singleMag = value
  }
}
