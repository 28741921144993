import {Magazine, MagazineState, SubscribedMag} from "@/utils/interfaces/magazine.interface";

const magazineState: MagazineState = {
  allMags: [] as Magazine[],
  userSubscribedMags: [] as SubscribedMag[],
  clickedMag: {} as Magazine,
  singleMag: {} as Magazine,
}

export default magazineState
