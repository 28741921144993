import userState from "@/store/modules/user/userState";
import userMutation from "@/store/modules/user/userMutation";
import userGetter from "@/store/modules/user/userGetter";
import {userActions} from "@/store/modules/user/userActions";

export default{
    namespaced:true,
    state: userState,
    mutations:userMutation,
    getters: userGetter,
    actions: userActions,
}
