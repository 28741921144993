import magazineState from "@/store/modules/magazine/magazineState";
import magazineMutations from "@/store/modules/magazine/magazineMutations";
import magazineGetters from "@/store/modules/magazine/magazineGetters";
import {magazineActions} from "@/store/modules/magazine/magazineActions";

export default{
  namespaced:true,
  state: magazineState,
  mutations: magazineMutations,
  getters: magazineGetters,
  actions: magazineActions,
}
