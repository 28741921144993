import axios, {AxiosInstance} from 'axios';
//import {VUE_APP_API_URL} from '@/.env';
import {AuthService} from "@/services/auth.service";

export class Http {
  protected isAuth: boolean;
  protected instance: AxiosInstance;

  constructor(status: any) {
    this.isAuth = status && status.auth ? status.auth : false;
    this.instance = axios.create({
      baseURL: `https://api.prod.cadreco.media/v1`,
      headers: {}
    })
  }

  public initRequestFetching() {
    if (this.isAuth) {
      this.instance.interceptors.request.use(config => {
        config.headers.authorization = AuthService.getBearer()
        return config
      }, error => {
        return Promise.reject(error);
      });
    }

    this.instance.interceptors.response.use(function (response) {
      return response;
    }, async function (error) {
      const originalRequest = error.config;

      if (error.response) {
        if (error.response.status === 401 && originalRequest.url === `/auth/refresh-token` && originalRequest.url !== `/auth/login`) {

          await AuthService.makeLogout();
          return Promise.reject(error);
        }


        if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== `/auth/login` && originalRequest.url !== `/auth/refresh-token`) {

          originalRequest._retry = true;
          try {
            const access_token = await AuthService.refreshTokens();
            const axios_instance = axios.create();


            originalRequest.headers.authorization = 'Bearer ' + access_token?.data.tokens.access.token.token;

            return axios_instance(originalRequest);
          } catch (e) {
            // console.log(e);
          }

        }
      }
      return Promise.reject(error)
    });
    return this.instance
  }


}

