import videoMutations from "@/store/modules/video/videoMutations";
import videoState from "@/store/modules/video/videoState";
import videoGetters from "@/store/modules/video/videoGetters";
import {videoActions} from "@/store/modules/video/videoActions";

export default {
  namespaced: true,
  state: videoState,
  mutations: videoMutations,
  getters: videoGetters,
  actions: videoActions,
}
