import {ActionTree} from "vuex";
import {MagazineService} from "@/services/magazine.service";
import {MagazineState} from "@/utils/interfaces/magazine.interface"
import {ErrorWrapper} from "@/utils/wrappers/error";
export const magazineActions: ActionTree<MagazineState, MagazineState> = {

  async verifyTransactionStatus ({commit},payload:{transactionId:number, type:string}) {

    try {
      return await MagazineService.verifyPayStatus(payload.transactionId, payload.type).then((resp) => {
        return resp.data.transaction === 'approved';
      })
    } catch (e) {
      return false;
      //throw new ErrorWrapper(e, e?.message)
    }
  },

  async getAllMagsAction({ commit }) {
    try {
      const response = await MagazineService.getAllMags()
      commit('SET_ALL_MAGS', response.data.content.reverse())
      return true
    }
    catch (e) {
      return false;
    }
  },

  async getSingleMagAction({commit}, id) {
    try {
      const response = await MagazineService.getMagById(id)
      commit('SET_SINGLE_MAG', response.data)
      return true
    }
    catch (e) {

      return false;
    }
  },

  async getUserSubscribedMagsAction({ commit}) {
    try {
      const response = await MagazineService.retrieveUserSubscribedMAgs()
      commit('SET_USER_SUBSCRIBED_MAGS', response.data.content)
      return true
    }
    catch (e) {
      return false;
    }
  },

  displayMagSummary({commit}, mag) {
    commit('DISPLAY_SUMMARY', mag)
  },

  readOrBuyMag({commit}, mag) {
    commit('BUY_OR_READ', mag)
  }
}
