import {ActionTree} from "vuex";
import {ErrorWrapper} from "@/utils/wrappers/error";
import {Video, VideoState} from "@/utils/interfaces/video.interface";
import {VideoService} from "@/services/video.service";

export const videoActions: ActionTree<VideoState, VideoState> = {
  async createVideoAction({commit}, payload: Video) {
    try {
      const resp = await VideoService.createVideo(payload)
      return true
    } catch (e:any) {
      throw new ErrorWrapper(e, e?.message)

    }
  },
  async getAllVideosAction({commit}) {
    try {
      const response = await VideoService.getAllVideos()
      // console.log(response)
      commit('SET_ALL_VIDEOS', response.data)
      return true
    } catch (e) {
      return false;
    }
  },
}
