import {Http} from './axios'
import {ResponseWrapper} from "@/utils/wrappers/response";
import {ErrorWrapper} from "@/utils/wrappers/error";

export class VideoService {
  static get videoEntity() {
    return 'videos'
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request(status:{auth:boolean} = { auth: true }) {
    const axiosInstance = new Http(status)
    return  axiosInstance.initRequestFetching();
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */


  static async getAllVideos() {
    try {
      const response = await this.request().get(`/${this.videoEntity}/`)

      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getVideoById(id: number) {
    try {
      const response = await this.request().get(`/${this.videoEntity}/${id}`)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {

      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  static async createVideo(data = {}) {
    try {
      const response = await this.request({ auth: true }).post(`/${this.videoEntity}/`, data)

      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      throw new ErrorWrapper(error)
    }
  }

  static async updateVideo (id: number, data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`/${this.videoEntity}/${id}`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
}
