import {Article, ArticleCategory, ArticleState} from "@/utils/interfaces/article.interface";

export default {
  SET_ON_HEADLINES_ARTICLES(state: ArticleState, value: Article[]) {
    state.articlesOnHeadline = value
  },

  SET_ARTICLES_CATEGORIES(state: ArticleState, value: ArticleCategory[]) {
    state.articlesCategories = value
  },

  SET_ALL_ARTICLES(state: ArticleState, value: Article[]) {
    state.allArticles = value
  },
}
