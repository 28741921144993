import {ResponseWrapper} from "@/utils/wrappers/response";

export class ErrorWrapper extends Error {
    success: boolean;
    meta: any;
    code: any;
    status: number;
    statusMessage: string;
    message: string;

    constructor(error: any, message: string | null = null) {
        super();

        if (error.response) {
            const responseData = error.response.data || {};
            this.success = responseData.success || false;
            this.meta = responseData.meta || false;
            this.code = responseData.code || false;
            this.status = error.response.status || 0;
            this.statusMessage = ResponseWrapper._getStatusMessage(this.status);
        } else {
            this.success = false;
            this.meta = false;
            this.code = false;
            this.status = 0;
            this.statusMessage = 'Unknown Error';
        }

        this.message = message || this._getResponseErrorMessage(error);
    }

    public _getResponseErrorMessage(error: any) {
        // console.log(error)
        return error.response.data.message || 'Unknown Error';
    }
}
