import {Company, CompanyDomain, CompanyState} from "@/utils/interfaces/company.interface";

export default {
  SET_ALL_COMPANIES(state: CompanyState, value: Company[]) {
    state.allCompanies = value
  },

  SET_COMPANIES_DOMAINS(state: CompanyState, value: CompanyDomain[]) {
    state.companiesDomains = value
  },
}
