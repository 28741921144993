import adsState from "@/store/modules/ads/adsState";
import adsMutations from "@/store/modules/ads/adsMutations";
import adsGetters from "@/store/modules/ads/adsGetters";
import {adsActions} from "@/store/modules/ads/adsActions";

export default {
  namespaced: true,
  state: adsState,
  mutations: adsMutations,
  getters: adsGetters,
  actions: adsActions,
}
