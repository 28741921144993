import {ActionTree} from "vuex";
import {ArticleState, Article} from "@/utils/interfaces/article.interface";
import {ArticlesService} from "@/services/articles.service";

export const articleActions: ActionTree<ArticleState, ArticleState> = {
  async getOnHeadlineArticlesAction({ commit }) {
    try {
      const response = await ArticlesService.getOnHeadlineArticles()
      commit('SET_ON_HEADLINES_ARTICLES', response.data.content)
      return true
    }
    catch (e) {
      return false;
    }
  },

  async getArticlesCategoriesAction({ commit }) {
    try {
      const response = await ArticlesService.getArticlesCategoriesList()
      commit('SET_ARTICLES_CATEGORIES', response.data.content)
      return true
    }
    catch (e) {
      return false;
    }
  },

  async getAllArticlesAction({ commit }) {
    try {
      const response = await ArticlesService.getAllArticles()
      commit('SET_ALL_ARTICLES', response.data.content.filter((article: Article) => article.status !== 'draft'))
      return true
    }
    catch (e) {
      return false;
    }
  },
}
