import {ActionTree} from "vuex";
import {UserRootState} from "@/utils/interfaces/user.interface";
import {AuthService} from "@/services/auth.service";
import {ErrorWrapper} from "@/utils/wrappers/error";

export const userActions: ActionTree<UserRootState, UserRootState> = {

  async login({commit}, payload: { email: string, password: string }) {
    try {
      const response = await AuthService.makeLogin(payload);
      commit('SET_CURRENT_USER', response.data.user)
      // console.log(response)
      return true;
    } catch (e) {
      // console.log(e)
      return false;
    }


  },

  async makeGoogleLogin({commit}, idToken: { idToken: string }) {
    try {
      const response = await AuthService.makeLoginWithGoogle(idToken);
      commit('SET_CURRENT_USER', response.data)
      // console.log(response)
      return true;
    } catch (e) {
      // console.log(e)
      return false;
    }
  },

  async makePasswordResetRequest({commit}, email: { email: string }) {
    try {
      const response = await AuthService.makePasswordResetRequest(email);

      // console.log(response)
      return true;
    } catch (e) {
      // console.log(e)
      return false;
    }
  },


  async makeResetPassword({commit}, {password, resetToken}: { password: string, resetToken: string }) {
    try {
      const response = await AuthService.makeResetPassword({password, resetToken});

      // console.log(response)
      return true;
    } catch (e) {
      // console.log(e)
      return false;
    }
  },

  async makePasswordUpdate({commit}, {oldPassword, newPassword}: { oldPassword: string, newPassword: string }) {
    try {
      const response = await AuthService.makeUpdatePassword({oldPassword, newPassword});

      // console.log(response)
      return true;
    } catch (e) {
      // console.log(e)
      return false;
    }
  },

  async makeActivateAccount({commit}, {token}: { token: string }) {
    try {
      const response = await AuthService.makeActivateAccount({token});
      // console.log(response)
      return true;
    } catch (error:any) {
      // console.log(e)
      throw new ErrorWrapper(error)
    }
  },

  async makeRegistration({commit}, {email, password, accountType, ProfileIdentity}: {
    email: string,
    password: string,
    accountType: string,
    ProfileIdentity: string
  }) {
    try {
      const response = await AuthService.makeRegistration({email, password, accountType, ProfileIdentity});
      // console.log(response)
      return true;
    } catch (e) {
      // console.log(e)
      return false;
    }
  },

  async makeRegistrationWithGoogle({commit}, {idToken}: { idToken: string }) {
    try {
      const response = await AuthService.makeRegistrationWithGoogle({idToken});
      commit('SET_CURRENT_USER', {currentUser: response.data})
      // console.log(response)
      return true;
    } catch (e) {
      // console.log(e)
      return false;
    }
  },

  async getCurrentUser({ commit }) {
    try {
      const response = await AuthService.getCurrentUser()
      commit('SET_CURRENT_USER', response)
      // console.log(response)
      return true
    }
    catch (error: any) {
      throw new ErrorWrapper(error)
    }
  },

  async makeLogout({commit}) {
    try {
      const response = await AuthService.makeLogout()
      // console.log(response)
      commit('SET_CURRENT_USER', null)
      return true
    }
    catch (e: any) {
      throw new ErrorWrapper(e)
    }
  },

  setRouteToBeRedirectAfterLoginAction({commit},path:string){
    commit('SET_ROUTE_TO_BE_REDIRECT', path)
  }

}
