import eventState from "@/store/modules/event/eventState";
import eventMutations from "@/store/modules/event/eventMutations";
import eventGetters from "@/store/modules/event/eventGetters";
import {eventActions} from "@/store/modules/event/eventActions";


export default{
  namespaced:true,
  state: eventState,
  mutations: eventMutations,
  getters: eventGetters,
  actions: eventActions,
}
