
/**
 * Check access permission to auth routes
 */
import store from '../store'
export function checkAccessMiddleware(to:any, from:any, next:any) {

    const currentUser = JSON.parse(String(localStorage.getItem('currentUser')))
    const isAuthRoute = to.matched.some((item:any) => item.meta.isAuth);
    const hasRight = to.matched.some((item:any) => {
        return (item.meta.neededRole)?.includes(currentUser?.role)
    });

    if (isAuthRoute && hasRight) return next()

    if (isAuthRoute && !hasRight) {
        if(currentUser){
            return next({ name: 'forbidden' })
        } else {
            store.commit('userModule/SET_ROUTE_TO_BE_REDIRECT',to.path )
            return next({ name: 'login' })
        }
    }

    if (isAuthRoute) return next({ name: 'login' })

    next()
}

