import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


/*------------------------------------- Bootstrap & Bootstrap Icons -------------------------------------*/
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import '../custom.scss';

/*------------------------------------- Vue Select -------------------------------------*/
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

/*------------------------------------- Vue3 Google Login -------------------------------------*/
import vue3GoogleLogin from 'vue3-google-login'

/*------------------------------------- Vue Quill -------------------------------------*/
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

/*------------------------------------- Vue Toastification -------------------------------------*/
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";

/*** vueuse/head ***/
import { createHead } from '@vueuse/head'
const head = createHead()

/*** vue cookies ****/
import VueCookies from 'vue-cookies'
const options: PluginOptions = {
  // You can set your default options here
  bodyClassName: ['fs-6']
};

/** Google tag Manager ***/
import VueGtag from "vue-gtag-next";

createApp(App)
  .use(store)
  .use(router)
  .use(VueCookies)
  .use(vue3GoogleLogin, {
    clientId: '159977541598-86kvp7h84nn6qvrgh1f8h8if38h0t7rj.apps.googleusercontent.com'
  })
  .use(Toast, options)
  .use(head)
    .use(VueGtag, {
        property: {
            id: "G-ZG3549FMJJ"
        }
    })
  .component('QuillEditor', QuillEditor)
  .component('vue-select', vSelect)
  .mount('#app')
