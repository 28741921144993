import {ActionTree} from "vuex";
import {EventService} from "@/services/event.service";
import {EventState, CadrecoEvent} from "@/utils/interfaces/event.interface";
import {ErrorWrapper} from "@/utils/wrappers/error";

export const eventActions: ActionTree<EventState, EventState> = {
  async createEventAction({commit}, payload:{event: CadrecoEvent,activeProfile:string}) {
    try {

      if(payload.activeProfile != 'enterprise')
        delete payload.event.companyId;

      const response =await EventService.createEvent(payload.event);

      return response.data;

    } catch (e:any) {
      throw new ErrorWrapper(e, e?.message)

    }
  },

  async updateEventAction({commit}, payload:{event: CadrecoEvent,EventId:number}) {
    try {
      await EventService.updateEvent(payload.EventId,payload.event)
      return true
    } catch (e:any) {
      throw new ErrorWrapper(e, e?.message)

    }
  },

  async getAllEventsAction({ commit }) {
    try {
      const response = await EventService.getAllEvents()
      commit('SET_ALL_EVENTS', response.data.content.filter((event: CadrecoEvent) => event.isValidated))

      const user = JSON.parse(String(localStorage.getItem("currentUser")))
      if (user) commit('SET_MY_EVENTS', response.data.content.filter((event: CadrecoEvent) => event.ProfileId === user.id))
      return true
    }
    catch (e) {

      return false;
    }
  },

  async getEventsCategoriesAction({ commit }) {
    try {
      const response = await EventService.getEventsCategories()
      commit('SET_EVENTS_CATEGORIES', response.data.content)

      return true
    }
    catch (e) {
      return false;
    }
  },

  async uploadEventImageAction({commit}, payload:{eventId:number,file:FormData},) {
    try {
      const response  = await EventService.upload(payload.eventId,payload.file);
      return response.data;
    } catch (e) {

      return false;
    }
  },

  async updateEventImagesAction({commit}, payload:{eventId:number,Images:Array<{url:string,legend:string}>},) {
    try {
      await EventService.updateImages(payload)
      return true
    } catch (e:any) {
      throw new ErrorWrapper(e, e?.message)
    }
  },
}
