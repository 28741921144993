import {Http} from './axios'
import {ResponseWrapper} from "@/utils/wrappers/response";
import {ErrorWrapper} from "@/utils/wrappers/error";

export class EventService {
  static get entity() {
    return 'events'
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request(status:{auth:boolean} = { auth: false }) {
    const axiosInstance = new Http(status)
    return  axiosInstance.initRequestFetching();
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */

  static async getEventsCategories() {
    try {
      const response = await this.request().get(`/${this.entity}/categories/`)

      const data = {
        content: response.data,
        total: Number(response.data.length)
      }

      return new ResponseWrapper(response, data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getAllEvents() {
    try {
      const response = await this.request().get(`/${this.entity}/`)
      //console.log(response)
      const data = {
        content: response.data,
        total: Number(response.data.length)
      }


      return new ResponseWrapper(response, data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getEventById(id: number) {
    try {
      const response = await this.request().get(`/${this.entity}/${id}`)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {

      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getEventCategoryById(id: number) {
    try {
      const response = await this.request().get(`/${this.entity}/categories/${id}`)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  static async createCategory(data = {}) {
    try {
      const response = await this.request({ auth: true }).post(`/${this.entity}/categories/`, data)
      //console.log(response)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      throw new ErrorWrapper(error)
    }
  }

  static async updateCategory(id: number, data = {}) {
    try {
      const response = await this.request({ auth: true }).patch(`/${this.entity}/categories/${id}`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async removeCategory(id: number) {
    try {
      const response = await this.request({ auth: true }).delete(`/${this.entity}/categories/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createEvent(data = {}) {
    try {
      const response = await this.request({ auth: true }).post(`/${this.entity}/`, data)
      //console.log(response)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updateEvent(id: number, data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`/${this.entity}/${id}`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async removeEvent(id: number) {
    try {
      const response = await this.request({ auth: true }).delete(`/${this.entity}/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }


  static async upload(id: number, data: any) {
    try {
      const response = await this.request({ auth: true }).post(`/${this.entity}/images?eventId=${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      //console.log(response);
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }


  static async updateImages(data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`/${this.entity}/images`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
