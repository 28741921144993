import {Http} from './axios'
import {ResponseWrapper} from "@/utils/wrappers/response";
import {ErrorWrapper} from "@/utils/wrappers/error";

export class MagazineService {
  static get magEntity () {
    return 'magazines'
  }

  static get paymentEntity () {
    return 'payment'
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request(status:{auth:boolean} = { auth: false }) {
    const axiosInstance = new Http(status)
    return  axiosInstance.initRequestFetching();
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */

  static async getAllMags () {
    try {
      const response = await this.request().get(`/${this.magEntity}`)
      const data = {
        content: response.data,
        total: Number(response.data.length)
      }

      return new ResponseWrapper(response, data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getMagById (id: number) {
    try {
      const response = await this.request({ auth: true }).get(`${this.magEntity}/${id}`)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  static async retrieveUserSubscribedMAgs () {
    try {
      const response = await this.request({ auth: true }).get(`${this.magEntity}/abo-magazine/me`)
      // console.log(response)
      const data = {
        content: response.data,
        total: Number(response.data.length)
      }

      return new ResponseWrapper(response, data)
    } catch (error: any) {
      throw new ErrorWrapper(error)
    }
  }
  static async verifyPayStatus (transaction_number: number ,type: string) {
    try {
      const response = await this.request({ auth: true }).get(`${this.paymentEntity}/${transaction_number}/${type}`)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async pay (data: any) {
    try {
      const response = await this.request({ auth: true }).post(`${this.paymentEntity}`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
