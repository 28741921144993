import {UserInterface, UserRootState} from "@/utils/interfaces/user.interface";

export default {

    SET_CURRENT_USER(state:UserRootState, value:UserInterface){
        state.currentUser = value;
    },

    SET_ROUTE_TO_BE_REDIRECT(state:UserRootState, value:string){
        state.triedRouteBeforeRedirectToLogin = value;
    }
}
