import userModule from "@/store/modules/user/index";
import articleModule from "@/store/modules/article";
import eventModule from "@/store/modules/event";
import companyModule from "@/store/modules/company";
import magazineModule from "@/store/modules/magazine";
import videoModule from "@/store/modules/video";
import adsModule from "@/store/modules/ads"
import {ModuleTree} from "vuex";

const  modules :ModuleTree<any> = {
    articleModule,
    userModule,
    eventModule,
    companyModule,
    magazineModule,
    videoModule,
    adsModule
}

export default modules
