import {Http} from './axios'
import {ResponseWrapper} from "@/utils/wrappers/response";
import {ErrorWrapper} from "@/utils/wrappers/error";

export class CompanyService {
  static get companyEntity() {
    return 'company-page'
  }

  static get companyDomainsEntity() {
    return 'company/domains'
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request(status:{auth:boolean} = { auth: true }) {
    const axiosInstance = new Http(status)
    return  axiosInstance.initRequestFetching();
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */

  static async getCompaniesDomains() {
    try {
      const response = await this.request().get(`/${this.companyDomainsEntity}/`)

      const data = {
        content: response.data,
        total: Number(response.data.length)
      }

      return new ResponseWrapper(response, data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getAllCompanies() {
    try {
      const response = await this.request().get(`/${this.companyEntity}/`)

      const data = {
        content: response.data,
        total: Number(response.data.length)
      }


      return new ResponseWrapper(response, data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getCompanyById(id: number) {
    try {
      const response = await this.request().get(`/${this.companyEntity}/${id}`)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {

      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getCompanyDomainsById(id: number) {
    try {
      const response = await this.request().get(`/${this.companyDomainsEntity}/${id}`)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  static async createCompanyDomain(data = {}) {
    try {
      const response = await this.request({ auth: true }).post(`/${this.companyDomainsEntity}/`, data)

      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      throw new ErrorWrapper(error)
    }
  }

  static async updateCompanyDomain (id: number, data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`/company/domains/${id}`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async removeCompanyDomain (id: number) {
    try {
      const response = await this.request({ auth: true }).delete(`/company/domains/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async createCompany(data = {}) {
    try {
      const response = await this.request({ auth: true }).post(`/${this.companyEntity}/`, data)

      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updateCompany(id: number, data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`/${this.companyEntity}/${id}`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async removeCompany(id: number) {
    try {
      const response = await this.request({ auth: true }).delete(`/${this.companyEntity}/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }


  static async uploadCompanyLogo(id: number, data: any) {
    try {
      const response = await this.request({ auth: true }).post(`/${this.companyEntity}/images/logo?companyId=${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      //console.log(response);
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async uploadCompanyBanner(id: number, data: any) {
    try {
      const response = await this.request({ auth: true }).post(`/${this.companyEntity}/images/banner?companyId=${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      //console.log(response);
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updateCompanyLogo(data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`/${this.companyEntity}/images/logo`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updateCompanyBanner(data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`/${this.companyEntity}/images/banner`, data)
      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
