import {CadrecoEvent, CadrecoEventCategory, EventState} from "@/utils/interfaces/event.interface";

export default {
  SET_ALL_EVENTS(state: EventState, value: CadrecoEvent[]) {
    state.allEvents = value
  },

  SET_MY_EVENTS(state: EventState, value: CadrecoEvent[]) {
    state.myEvents = value
  },

  SET_EVENTS_CATEGORIES(state: EventState, value: CadrecoEventCategory[]) {
    state.eventsCategories = value
  },
}
