import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import {checkAccessMiddleware} from "@/router/middleware";
import { trackRouter } from "vue-gtag-next";
// functions
function formatArticleSlug(inputString: string): string {
  const normalizedString = inputString.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const cleanedString = normalizedString.replace(/[^a-zA-Z0-9\s]/g, '');
  const hyphenatedString = cleanedString.replace(/\s+/g, '-');
  return hyphenatedString;
}

// .env
const vueAppDomainTitle = `Média d’information du monde des affaires du bénin - CADRECO`

// layouts
const AppLayout = () => import('../layouts/AppLayout.vue')
const AuthLayout = () => import('../layouts/AuthLayout.vue')

// pages
const LoginPage = () => import('../pages/auth/LoginPage.vue')
const RegisterPage = () => import('../pages/auth/RegisterPage.vue')

// views
const HomeView = () => import('../views/HomeView.vue')
const ArticleView = () => import('../views/articles/ArticleView.vue')
const CategoryView = () => import('../views/articles/CategoryView.vue')
const AgendaView = () => import('../views/events/AgendaView.vue')
const AllEventsView = () => import('../views/events/AllEventsView.vue')
const MyEventsView = () => import('../views/events/MyEventsView.vue')
const EventDetailsView = () => import('../views/events/EventDetailsView.vue')
const CreateEventView = () => import('../views/events/CreateEventView.vue')
const AllMagsView = () => import('../views/magazine/AllMagsView.vue')
const ReadMagView = () => import('../views/magazine/ReadMagView.vue')
const UserProfileView = () => import('../views/user/UserProfileView.vue')
const SearchArticleView = () => import('../views/search/SearchArticleView.vue')
const VideoListView = () => import ('../views/videos/VideosListView.vue')
const VideoDetailsView = () => import('../views/videos/VideoDetailsView.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    component: AppLayout,
    children: [
      /* ----- AUTH ROUTES ----- */
      {
        path: 'auth',
        component: AuthLayout,
        children: [
          {
            name: 'login',
            path: 'login',
            component: LoginPage
          },
          {
            name: 'register',
            path: 'register',
            component: RegisterPage
          },
          {
            path: 'profile',
            name: 'user profile',
            component: UserProfileView
          },
        ]
      },

      /* ----- VIEWS ROUTES ----- */
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: { title: `${vueAppDomainTitle} | ACCUEIL`,isAuth:false , neededRole:[] },
        alias: ['accueil']
      },
      {
        path: 'article/:article_id/:article_slug',
        name: 'article details',
        meta: { title: `${vueAppDomainTitle} | ACCUEIL`,isAuth:false, neededRole:[] },
        component: ArticleView
      },
      {
        path: '/actualite/detail/:article_id/:article_slug',
        redirect: to => {
          // Récupérez le paramètre d'URL article_slug
          const articleSlug = to.params.article_slug as string;

          // Utilisez votre fonction de formatage
          const formattedSlug = formatArticleSlug(articleSlug);

          // Redirigez l'utilisateur avec le nouveau article_slug formaté
          return { name: 'article details', params: { article_id: to.params.article_id, article_slug: formattedSlug } };
        },
      },
      {
        path: 'category/:category_slug',
        name: 'article category',
        meta: { title: `${vueAppDomainTitle} | ACCUEIL`,isAuth:false, neededRole:[] },
        component: CategoryView
      },
      {
        path: 'agenda',
        name: 'agenda',
        meta: { title: `${vueAppDomainTitle} | ACCUEIL`,isAuth:false, neededRole:[] },
        component: AgendaView
      },
      {
        path: 'events',
        name: 'all events',
        meta: { title: `${vueAppDomainTitle} | ACCUEIL`,isAuth:false, neededRole:[] },
        component: AllEventsView
      },
      {
        path: 'my-events',
        name: 'my events',
        meta: { title: `${vueAppDomainTitle} | ACCUEIL`,isAuth:true, neededRole:["user","admin"] },
        component: MyEventsView
      },
      {
        path: 'event/:event_id/:event_slug',
        name: 'event details',
        meta: { title: `${vueAppDomainTitle} | ACCUEIL`,isAuth:false, neededRole:[] },
        component: EventDetailsView
      },
      {
        path: '/evenements/:event_id/details',
        redirect: to => {
          return { name: 'event details', params: { event_id: to.params.event_id, event_slug: 'cadreco-event' } };
        },
      },
      {
        path: 'create-event',
        name: 'create event',
        meta: { title: `${vueAppDomainTitle} | ÉVÈNEMENT`,isAuth:true, neededRole:["user","admin"] },
        component: CreateEventView
      },
      {
        path: 'mags',
        name: 'all mags',
        alias: ['magazines'],
        component: AllMagsView
      },
      {
        path: 'read-mag/:mag_number/:mag_title/:mag_id',
        name: 'read mag',
        meta: { title: `${vueAppDomainTitle} | MAGAZINE`,isAuth:true, neededRole:["user","admin"] },
        component: ReadMagView
      },
      {
        path: 'videos',
        name: 'videos list',
        meta: { title: `${vueAppDomainTitle} | VIDEOS`, isAuth: false, neededRole:[] },
        component: VideoListView
      },
      {
        path: 'videos/:video_id/:video_slug',
        name: 'video_details',
        meta: { title: `${vueAppDomainTitle} | VIDEOS`, isAuth: false, neededRole:[] },
        component: VideoDetailsView
      },
      {
        path: 'search',
        name: 'search article',
        meta: { title: `${vueAppDomainTitle} | RECHERCHE`,isAuth:false, neededRole:[] },
        component: SearchArticleView
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        const targetElement = document.querySelector(to.hash);
        if (targetElement) {
          const rect = targetElement.getBoundingClientRect();
          const offsetY = window.pageYOffset || document.documentElement.scrollTop;
          const targetTop = rect.top + offsetY;

          resolve({ top: targetTop, behavior: 'smooth' });
        } else {
          reject({ behavior: 'smooth' });
        }
      });
    } else if (savedPosition) {
      // Si une position précédemment sauvegardée existe, utilisez-la
      return savedPosition;
    } else {
      // Sinon, faites défiler vers le haut de la page
      return { top: 0 };
    }
  },
})

router.beforeEach(checkAccessMiddleware);
trackRouter(router,{useScreenview: true});
export default router
