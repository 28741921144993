import {ActionTree} from "vuex";
import {CompanyService} from "@/services/company.service";
import {CompanyState, Company} from "@/utils/interfaces/company.interface"
import {ErrorWrapper} from "@/utils/wrappers/error";

export const companyActions: ActionTree<CompanyState, CompanyState> = {
    async createCompanyAction({commit}, payload: Company) {
        try {
            const resp = await CompanyService.createCompany(payload)
            return resp.data.companyPage.id;
        } catch (e:any) {
            throw new ErrorWrapper(e, e?.message)

        }
    },
    async getAllCompaniesAction({commit}) {
        try {
            const response = await CompanyService.getAllCompanies()
            commit('SET_ALL_COMPANIES', response.data.content.companies)
            return true
        } catch (e) {
            return false;
        }
    },

    async getCompaniesDomainsAction({commit}) {
        try {
            const response = await CompanyService.getCompaniesDomains()
            commit('SET_COMPANIES_DOMAINS', response.data.content)
            return true
        } catch (e) {
            return false;
        }
    },

    async uploadCompanyLogoAction({commit}, payload: { companyId: number, file: FormData }) {
        try {
            const response = await CompanyService.uploadCompanyLogo(payload.companyId, payload.file)
            return response.data;
        } catch (e:any) {
            throw new ErrorWrapper(e, e?.message)

        }
    },

    async uploadCompanyBannerAction({commit}, payload: { companyId: number, file: FormData }) {
        try {
            await CompanyService.uploadCompanyBanner(payload.companyId, payload.file)
            return true
        } catch(e:any) {
            throw new ErrorWrapper(e, e?.message)

        }
    },
    async updateCompanyLogoAction({commit}, payload: { companyId: number, logo: string }) {
        try {
            await CompanyService.updateCompanyLogo(payload)
            return true
        } catch (e:any) {
            throw new ErrorWrapper(e, e?.message)

        }
    },
    async updateCompanyBannerAction({commit}, payload: { companyId: number, logo: string }) {
        try {
            await CompanyService.updateCompanyBanner(payload)
            return true
        } catch (e:any) {
            throw new ErrorWrapper(e, e?.message)

        }
    },


}
