import {Http} from './axios'
import {ResponseWrapper} from "@/utils/wrappers/response";
import {ErrorWrapper} from "@/utils/wrappers/error";

export class AdsService {
  static get adsEntity() {
    return 'pubs'
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request(status:{auth:boolean} = { auth: true }) {
    const axiosInstance = new Http(status)
    return  axiosInstance.initRequestFetching();
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */


  static async getAllActiveAds() {
    try {
      const response = await this.request().get(`/${this.adsEntity}/actives`)

      return new ResponseWrapper(response, response.data)
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updateAdsLinkClick(AdsId: number) {
    try {
      const response = await this.request().get(`/${this.adsEntity}/${AdsId}/actions/click`)
      // return new ResponseWrapper(response, response.data)
      return true
    } catch (error: any) {
      // console.log(error)
      return false
      // const message = error.response.data ? error.response.data.error : error.response.statusText
      // throw new ErrorWrapper(error, message)
    }
  }

}
