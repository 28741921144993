export class ResponseWrapper {
    data: any = {};
    message: string | null;
    status: number;
    statusMessage: string;
    success: boolean; // Assuming success is a boolean
    response: any = {};

    constructor(response: any, data: any = {}, message: string | null = null) {
        this.data = data;
        this.success = response.data.success;
        this.status = response.status;
        this.statusMessage = ResponseWrapper._getStatusMessage(this.status);
        this.message = message;
    }

    static  _getStatusMessage(status: number) {
        let message = '';
        switch (status) {
            case 200:
                message = 'All done. Request successfully executed';
                break;
            case 201:
                message = 'Data successfully created';
                break;
            case 400:
                message = 'Bad Request';
                break;
            case 401:
                message = 'Need auth';
                break;
            case 404:
                message = 'Not found';
                break;
            case 503:
                message = 'Service unavailable. Try again later';
                break;
            default:
                message = 'Something wrong. Client default error message';
                break;
        }
        return message;
    }
}

