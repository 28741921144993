import articleMutations from "@/store/modules/article/articleMutations";
import articleGetters from "@/store/modules/article/articleGetters";
import {articleActions} from "@/store/modules/article/articleActions";
import articleState from "@/store/modules/article/articleState";


export default{
  namespaced:true,
  state: articleState,
  mutations: articleMutations,
  getters: articleGetters,
  actions: articleActions,
}
