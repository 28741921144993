import companyState from "@/store/modules/company/companyState";
import companyMutations from "@/store/modules/company/companyMutations";
import companyGetters from "@/store/modules/company/companyGetters";
import {companyActions} from "@/store/modules/company/companyActions";

export default{
  namespaced:true,
  state: companyState,
  mutations: companyMutations,
  getters: companyGetters,
  actions: companyActions,
}
